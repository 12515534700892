<template>
  <div>
    <div v-if="!loadingPage">
      <div v-if="page">
        <h1>{{ page.name }}</h1>
        <div class="pt-2" v-html="page.body"></div>
      </div>
    </div>
    <v-skeleton-loader v-else class="pt-2" type="article"></v-skeleton-loader>
  </div>
</template>
<script>
import { PagesActions, PagesGetters } from "@pages/shared/pages.store";

export default {
  data() {
    return {
      slug: this.$route.params.slug ? this.$route.params.slug : null,
    }
  },
  computed: {
    loadingPage: PagesGetters.getLoadingPage,
    page: PagesGetters.getPage,
  },
  watch: {
    '$route.params.slug': async function (slug) {
      this.slug = slug ?? null;
      if (this.slug) {
        await PagesActions.fetchPage(this.slug);
      }
    },
  },
  async mounted() {
    if (this.slug) {
      await PagesActions.fetchPage(this.slug);
    }
    // await PagesActions.fetchCourses();
  },
  methods: {

  },
  components: {

  }
}
</script>